@import url('https://fonts.googleapis.com/css2?family=Bitter:wght@300;400;700&display=swap');
body{
    background-color: #f8f9fa!important;
    font-family: 'Bitter', serif;
}
.navbar-light .navbar-toggler {
    border-color: rgba(0,0,0,0);
}
.navbar-toggler:focus {

    box-shadow: none;
}
a {
    color: #ff327d;
    text-decoration: none;
}
a :hover{
    color: #ff78a9!Important;
    text-decoration: none;
}
a :focus{
    color: #ff78a9;
    text-decoration: none;
}
a:visited {
    color: #ff78a9; /* Цвет посещенных ссылок */
   }
   a:active {
    color: #ff78a9; /* Цвет активных ссылок */
 }
.btn-pink{
    background-color: #ff4081; 
    border-color: #ff4081;
    color: white;
    margin-right: 1rem;
}
.btn-pink:hover{
    color: white;
}

.btn-outline.pink{
    background-color: white; 
    border-color: #d4d4d4;
    color: black;
    margin-right: 1rem;
}

.btn-outline.pink:hover{
    background-color: #ff4081; 
    border-color: #ff4081;
    color: white;
}

.menu-basket-text{
    font-size: 10px;
    line-height: 6px;
    color: rgb(150, 150, 150);
}
.menu-basket-text-full{
    font-size: 10px;
    line-height: 6px;
    color: #ff4081;
}
.color-pink{
    color: #ff4081;
}


.mh100{
 min-height: 60vh;
}
.br7{
    border-radius: 0px;
}
.brt7{
    border-radius:  0px 0px 0 0;
}
.footer{

        background-color: #383838;
        font-size: 11.5px;
        font-weight: bold;
        color: rgb(189, 196, 203)
    
}
.footer .card {

     background-color: #383838; 
     border: 1px solid #383838; 

}
hr {
    border-width: 3px
}

.social>i {
    padding: 1%;
    font-size: 15px
}

.social>i:hover {
    color: #957bda;
    cursor: pointer
}

.policy>div {
    padding: 4px
}

.heading {
    font-family: 'Titillium Web', sans-serif;
    color: white
}

.divider {
    border-top: 2px solid rgba(189, 196, 203, 0.5);
}


.hidden {
    display: none;
}
.boxes label:first-of-type {
    margin-right: 15.98px;
}
.boxes label {
    display: block;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 19px 22.17px;
    border: 1px solid #E8E8E8;
    border-radius: 0px;
    cursor: pointer;
    min-height: 110px;
    width: 307px;
    word-wrap: break-word;
    -webkit-transition: border ease-in-out 0.3s;
    -o-transition: border ease-in-out 0.3s;
    transition: border ease-in-out 0.3s;
}
 .boxes {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding-bottom: 3rem;
}

.boxes label.checked {
    border: 1px solid #ff80ab;
}

.boxes label .title {
    font-size: 15px;
    line-height: 19px;
    color: #000;
    margin-bottom: 5px;
}

.boxes label .description {
    color: #999999;
    font-size: 14px;
    line-height: 19px;
}

.form-element, .field-input, .react-dadata__input {
    padding: 10px 16.42px 10px;
    border-radius: 0px;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 17px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%;
    color: #A4A6A6;
    border: 1px solid #E8E8E8;
}
.form-element.error, .field-input.error {
    border-color: #ff4081;
    color: #ff4081;
}
.field-input-box label {
    position: absolute;
    color: #A4A6A6;
    font-size: 16px;
    line-height: 24px;
    left: 16.42px;
    top: 22.99px;
    cursor: pointer;
}
.field-input:focus, .field-input:hover {
    border: 1px solid #ff4081;
}


.field-input:focus, .field-input:hover {
    border: 1px solid #ff4081;
}
.form-element:active, .form-element:hover, .form-element:focus, .field-input:active, .field-input:hover, .field-input:focus, .react-dadata__input {
    outline: 0;
    outline-offset: 0;
}
.react-dadata__input:focus {
    border-color: #E8E8E8;
    box-shadow: none;
}
.react-dadata__input {
    border-radius: 0px;
    height: auto;
}

.btn-dark {
    color: #fff;
    background-color: #b0b0b0;
    border-color: #b0b0b0;
}
.nav-link,  .nav-link:focus, .nav-link:hover {

    color: #000;
}
.bg-green{
    background-color: #007830;
}   


.CategoryItem-img{
    width: 100%;
    height: 300px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

element.style {
    cursor: pointer;
}
.br7 {
    border-radius: 0px;
}
.text-center {
    text-align: center!important;
}
.border-light {
    border-color: #f8f9fa!important;
}
.card {
    background-color: rgba(255, 255, 255, 0);
    margin-bottom: 20px;
}
.MuiOutlinedInput-root{
    border-radius: 0!Important;

}
.MuiFormControl-root, .MuiTextField-root {
    width: 50%;
}
.MuiOutlinedInput-input, .MuiInputBase-input{
    height: 10px!Important;
    width: 100%;
}
.cart-btn {
    background: #ff4081;

    border-radius: 3px;
    padding: 12px 25px;
    font-size: 14px;
    line-height: 16px;
    color: #fff;
    background: #ff4081;
    display: inline-block;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border: none;
    cursor: pointer;
}
.field-checkbox .checkbox+label {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 14px;
    line-height: 18px;
    color: #484848;
    display: block;
    position: relative;
    padding-left: 30.51px;
    cursor: pointer;
}
.order .order-options .field-checkbox {
    margin-bottom: 17px;
}
.field-checkbox {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #484848;
}
.field-checkbox .checkbox {
    position: absolute;
    left: -99999px;
}
[type="checkbox"], [type="radio"] {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0;
}
.field-checkbox .checkbox+label:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 14.91px;
    display: block;
    height: 14px;
    border: 1px solid #B0BEC5;
    background-color: #fff;
}
.field-checkbox .checkbox+label:after {
    display: block;
    left: 3px;
    top: 2px;
    width: 8px;
    height: 6px;
    border-radius: 1px;
    border-left: 2px solid #fff;
    border-bottom: 2px solid #fff;
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
    content: "";
    position: absolute;
    -webkit-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    transition: all 0.2s ease;
    opacity: 0;
}
.field-checkbox .checkbox:checked+label:before {
    background: #ff4081;
    border: 1px solid #ff4081;
}
.field-checkbox .checkbox:checked+label:after {
    opacity: 1;
}

@media (max-width: 1400px) {
    .CategoryItem-img{
        height: 210px;
    }
    
}

@media (max-width: 1200px ) {
    .CategoryItem-img{
        height: 200px;
    }
}

@media (max-width: 992px ) {
    .CategoryItem-img{
        height: 190px;
    }
}

@media (max-width: 768px) {
    .CategoryItem-img{
        height: 200px;
    }
}

@media (max-width: 576px) {
    .CategoryItem-img{
        height: 180px;
    }
}










@-webkit-keyframes scaleAnimation {
  0% {
    opacity: 0;
    transform: scale(1.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes scaleAnimation {
  0% {
    opacity: 0;
    transform: scale(1.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@-webkit-keyframes drawCircle {
  0% {
    stroke-dashoffset: 151px;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes drawCircle {
  0% {
    stroke-dashoffset: 151px;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
@-webkit-keyframes drawCheck {
  0% {
    stroke-dashoffset: 36px;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes drawCheck {
  0% {
    stroke-dashoffset: 36px;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
#successAnimationCircle {
  stroke-dasharray: 151px 151px;
  stroke: #ff4081;
}

#successAnimationCheck {
  stroke-dasharray: 36px 36px;
  stroke: #ff4081;
}

#successAnimationResult {
  fill: #ff4081;
  opacity: 0;
}

#successAnimation.animated {
  -webkit-animation: 1s ease-out 0s 1 both scaleAnimation;
          animation: 1s ease-out 0s 1 both scaleAnimation;
}
#successAnimation.animated #successAnimationCircle {
  -webkit-animation: 1s cubic-bezier(0.77, 0, 0.175, 1) 0s 1 both drawCircle, 0.3s linear 0.9s 1 both fadeOut;
          animation: 1s cubic-bezier(0.77, 0, 0.175, 1) 0s 1 both drawCircle, 0.3s linear 0.9s 1 both fadeOut;
}
#successAnimation.animated #successAnimationCheck {
  -webkit-animation: 1s cubic-bezier(0.77, 0, 0.175, 1) 0s 1 both drawCheck, 0.3s linear 0.9s 1 both fadeOut;
          animation: 1s cubic-bezier(0.77, 0, 0.175, 1) 0s 1 both drawCheck, 0.3s linear 0.9s 1 both fadeOut;
}
#successAnimation.animated #successAnimationResult {
  -webkit-animation: 0.3s linear 0.9s both fadeIn;
          animation: 0.3s linear 0.9s both fadeIn;
}





figure.zoom {
  background-position: 50% 50%;
  position: relative;
  width: 100%;
  overflow: hidden;
  cursor: zoom-in;
}
figure.zoom img:hover {
  opacity: 0;
}
figure.zoom img {
  transition: opacity 0.5s;
  display: block;
  width: 100%;
}

.st0{fill:#25D366;}
.st1{fill-rule:evenodd;clip-rule:evenodd;fill:#FFFFFF;}

.st0v{fill:#4D76A1;}
.st1v{fill-rule:evenodd;clip-rule:evenodd;fill:#FFFFFF;}

.st0t {
  fill: url(#SVGID_1_);
}
	.st1t{fill:#FFFFFF;}
	.st2t{fill:#D2E4F0;}
	.st3t{fill:#B5CFE4;}

  .st0i{fill:url(#SVGID_1_i);}
	.st1i{fill:#FFFFFF;}